@import "semantic-ui-css/semantic.min.css";
@import "bootstrap/dist/css/bootstrap.css";
@import "./stylesheets/main.css";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap");

.fc-more-popover {
  max-height: 95%;
  overflow-y: auto;
}
.checkbox::after{
  display: none;
}
body {
  background-color: #f5f5f5;
}

a {
  color: #c00000;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400;
}

.children {
  background-color: white;
  border-radius: 0;
  border: 1px solid #ddd;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
}

.ui.blue.button,
.ui.blue.buttons .button,
.ui.positive.button,
.ui.positive.buttons .button,
.ui.negative.button,
.ui.negative.buttons .button {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
}

.ui.negative.button,
.ui.negative.buttons .button {
  background-color: #666666 !important;
}

.ui.positive.button,
.ui.positive.buttons .button {
  background-color: #c00000 !important;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #c00000 !important;
}

.ui.fluid.dropdown,
.ui.selection.dropdown {
  border-radius: 0 !important;
}

.ui.form {
  padding: 25px;
  border: 1px solid #ddd;
  box-shadow: 0 0.375em 0.375em #e5e5e5;
}

.ui.form.clean {
  padding: 0;
  border: 0;
  box-shadow: none;
}

.ui.modal > .close {
  color: #222;
  top: 0;
  right: 0;
}
.modal {
  overflow: initial;
}

#MeetingCard {
  width: 100%;
  padding: 0;
  margin: 0;
}

#MeetingCard > div {
  width: 100% !important;
  border-radius: 0 !important;
  border: 1px solid #ddd;
  box-shadow: none !important;
}

#container-dashboard-area .ui.selection.dropdown {
  width: 100% !important;
}

#container-dashboard-area .ui.divided.list.list-area.flex-grow-1 {
  background: #f5f5f5;
  border: 1px solid #ddd;
  width: 100% !important;
  max-width: 100% !important;
}

#container-dashboard-area .ui.divided.list.list-area.flex-grow-1 > .item {
  margin: auto 1em;
  list-style-type: disc;
  color: #c00000;
}

#container-dashboard-area
  .ui.divided.list.list-area.flex-grow-1
  > .item
  .content:hover,
#container-dashboard-area
  .ui.divided.list.list-area.flex-grow-1
  > .item
  .right.floated.content:hover {
  color: #5bc8d5;
}

.ui.table th {
  background-color: #333 !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: white !important;
}

#AdminArea {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
}

.tox-notifications-container {
  display: none !important;
}
#AdminArea table {
  background: none;
  border: 0;
  padding: 0;
}

#AdminAreaDetails .ui.input {
  width: calc(100% - 62.1px);
}

#AdminAreaDetails .ui.input input {
  border-radius: 0 !important;
}

#header {
  background-color: #efefef;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}

.breadcrumb li {
  font-size: 12px;
}

.breadcrumb a {
  color: #333;
  text-decoration: none;
}

tr.table-meetings{
  background-color: #fffbf7!important;
  color: #9e7738!important
}

tr.table-meetings.positive{
  background-color: green!important;
}

tr.table-meetings.negative{
  background-color: red!important;
}